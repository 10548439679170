/* 범례 (기본) */
.my-legend {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  //background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
}
.my-legend .legend-scale .legend-labels {
  margin: 0;
  padding: 0;
}
.my-legend .legend-scale .legend-labels>li {
  list-style: none;
  margin-top: 12px;
  //color: #1E1D1F;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.385em;
  letter-spacing: -0.26px;
}
.my-legend .legend-scale .legend-labels>li:first-child {
  margin-top: 0;
}
.my-legend .legend-scale .legend-labels>li>span {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  border: 1px solid #FF1010;
}

/* 범례 (블랙) */
.blackTheme .my-legend {
  background-color: rgba(47, 47, 47, 0.96);
  box-shadow: none;
}
.blackTheme .my-legend .legend-scale .legend-labels>li {
  color: #FFFFFF;
}

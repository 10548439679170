@charset "utf-8";

/* reset */
* {
	outline: 0;
	box-sizing: border-box;
    -webkit-text-size-adjust: none;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
html, body {
	width: 100%;
	height: 100%;
	min-width: 1400px;
	background-color: #FFFFFF;
    color: #1E1D1F;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    letter-spacing: normal;
	font-family: 'Noto Sans KR', sans-serif;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
ol, ul, dl, li {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* 공통 */
.cInputbox {
    width: 100%;
    height: 68px;
    padding: 23px;
    background-color: #FFFFFF;
    border: 1px solid #3A3D46;
    color: #1E1D1F;
    font-size: 1rem;
    line-height: 1.125em;
    letter-spacing: -0.32px;
    font-family: 'Noto Sans KR', sans-serif;
}
.cInputbox::-webkit-input-placeholder {
	color: #66686F;
}
.cInputbox::-moz-placeholder {
	color: #66686F;
}
.cInputbox::-ms-input-placeholder {
	color: #66686F;
}
.cCheckbox {
	display: inline-block;
    vertical-align: middle;
    margin-right: 32px;
}
.cCheckbox input[type='checkbox'] {
	width: 0;
    height: 0;
    position: absolute;
    padding: 0;
    margin: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
}
.cCheckbox input[type='checkbox'] + label {
	display: inline-block;
	vertical-align: top;
	margin-left: 28px;
	color: #66686F;
	font-size: 1rem;
	line-height: 1.25em;
	text-indent: -28px;
}
.cCheckbox input[type='checkbox']:disabled + label {
	cursor: default;
}
.cCheckbox input[type='checkbox'] + label:before {
	content: '';
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	margin-right: 8px;
	background: #FFFFFF;
    border: 1px solid #3A3D46;
}
.cCheckbox input[type='checkbox']:checked + label:before {
	background: url('../images/icon-checkbox-checked.svg') no-repeat center;
	background-size: contain;
    border: 1px solid #386AEA;
}

/* 로그인 */
.cLogin {
    width: 100%;
    height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}
.cLogin:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/img-login.svg') no-repeat center;
    background-size: cover;
}
.cLogin:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(106deg, rgba(0, 0, 0, 0.56) 16.88%, rgba(0, 0, 0, 0.24) 102.66%);
}
.cLogin .loginArea {
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    position: relative;
    z-index: 1;
}
.cLogin .loginArea .loginTop {
    display: inline-block;
    margin: 36px 0;
}
.cLogin .loginArea .loginTop>img {
    width: auto;
    height: 64px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 14px;
}
.cLogin .loginArea .loginTop>span {
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 3.75rem;
    font-weight: 700;
    letter-spacing: -3px;
}
.cLogin .loginArea .loginBox {
    max-width: 626px;
    display: inline-block;
    margin: 36px 0;
    padding: 40px 48px;
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
}
.cLogin .loginArea .loginBox .loginTit {
    color: #555252;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.48px;
}
.cLogin .loginArea .loginBox .cInputbox {
    margin-top: 12px;
}
.cLogin .loginArea .loginBox .loginFlex {
    min-height: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 12px;
}
.cLogin .loginArea .loginBox .loginFlex .loginLink {
    margin-left: auto;
    color: #386AEA;
    font-size: 1rem;
    text-decoration: none;
}
.cLogin .loginArea .loginBox .loginBtn {
    width: 100%;
    height: 68px;
    margin-top: 24px;
    background-color: #386AEA;
    border: 1px solid #386AEA;
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 66px;
    font-weight: 700;
    text-emphasis: center;
    border-radius: 4px;
}


.wrap {display:table; position:relative; width:100%; height:100%;}
.error-wrap {
  display: table-cell;
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  .error-area {
    *zoom: 1;
    display: inline-block;
    position:relative;
    padding-top:76px;
    font-size:56px;
    color:#555;
    letter-spacing:-1px;
    span {
      font-weight:700;
      font-size:70px;
      color:#cf0000;
    }
  }
  .error-area:after {
    content:"";
    display:block;
    position:absolute;
    left:50%;
    top:0px;
    width:108px;
    height:108px;
    margin-left:-54px;
    margin-top:-54px;
    background:url('../../resources/images/img_error.png') no-repeat;
  }
  .message-area {
    margin-top:34px;
    .tit {
      font-weight:700;
      font-size:28px;
      color:#555;
    }
    .cont {
      margin-top:20px;
      font-size:18px;
      color:#555;
      line-height:26px;
    }
  }
}
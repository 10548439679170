/* 말풍선 (기본) */
.ol-popup {
    min-width: 285px;
    position: absolute;
    top: -26px;
    left: 22px;
    padding: 16px;
    //background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.ol-popup:after, .ol-popup:before {
    content: " ";
    width: 0;
    height: 0;
    position: absolute;
    top: 15px;
    left: -11px;
    z-index: 1;
    border: solid transparent;
    pointer-events: none;
    transform: rotate(90deg);
}
.ol-popup:after {
    margin-left: -10px;
    border-width: 10px;
    //border-top-color: #FFFFFF;
}
.ol-popup:before {
    margin-left: -11px;
    border-width: 11px;
    border-top-color: #FFFFFF;
}
.ol-popup-closer {
    position: absolute;
    top: 20px;
    right: 16px;
    color: #383F43;
    font-size: 1rem;
    line-height: 1em;
    text-decoration: none;
}
.ol-popup-closer:hover {
    color: #383F43;
}
.ol-popup-closer:after {
    content: "✖";
}
.ol-popup .ol-popup-title {
    width: 100%;
    height: auto;
    margin-bottom: 4px;
    padding: 4px 0;
    //color: #262728;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
}
.ol-popup .ol-popup-table {
    width: 100%;
    height: auto;
    table-layout: fixed;
    border-collapse: collapse;
}
.ol-popup .ol-popup-table th {
    height: 32px;
    vertical-align: middle;
    padding: 6px 8px;
    background-color: transparent;
    border-bottom: 1px solid #DADADA;
    //color: #656565;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: -0.26px;
    text-align: left;
}
.ol-popup .ol-popup-table td {
    height: 32px;
    vertical-align: middle;
    padding: 6px 8px;
    background-color: transparent;
    border-bottom: 1px solid #DADADA;
    //color: #383F43;
    font-size: 0.813rem;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: -0.26px;
    text-align: left;
    word-break: break-all;
}

/* 말풍선 (블랙) */
.backTheme .ol-popup {
    background-color: rgba(47,47,47,0.96);
    border: 1px solid rgba(47,47,47,0.96);
    box-shadow: none;
}
.backTheme .ol-popup:after {
    border-top-color: rgba(47, 47, 47, 0.96);
}
.backTheme .ol-popup:before {
    border-top-color: rgba(47, 47, 47, 0.96);
}
.backTheme .ol-popup-closer {
    color: #FFFFFF;
}
.backTheme .ol-popup-closer:hover {
    color: #FFFFFF;
}
.backTheme .ol-popup .ol-popup-title {
    color: #FFFFFF;
}
.backTheme .ol-popup .ol-popup-table th {
    border-bottom: 1px solid rgba(218, 218, 218, 0.6);
    color: #E5E5E5;
}
.backTheme .ol-popup .ol-popup-table td {
    border-bottom: 1px solid rgba(218, 218, 218, 0.6);
    color: #FFFFFF;
}
